<template>
  <div></div>
</template>

<script>
export default {
  name: 'Discord',
  async mounted () {
    // Discord連携設定していない判定のときは最新の情報を取得する
    if (!this.isDiscordLinkage) await this.$store.dispatch('settingGroup/getGroupSetting', 'discordServerID')

    if (!this.isDiscordLinkage || !this.code) {
      this.$router.push({ name: 'notfound' })
      return
    }
    // Discordサーバーへの参加
    // functionsでのtry-catchだと処理が間に合わなかったのでここで実施
    try {
      await this.$store.dispatch('functions/joinedDiscord', this.code)
      this.$store.commit('setTelop', { show: true, msg: '限定グループへの参加 / ユーザー登録を完了しました。', type: 'success' })
      this.$router.push({ name: 'home' })
    } catch {
      this.$store.commit('setTelop', { show: true, msg: '限定グループへの参加に失敗しました。\nHomeメニューから再度お試しください。', type: 'error' }, { root: true })
      this.$router.push({ name: 'error' })
    }
  },
  computed: {
    /**
     * @return {String} oauthのコード
     */
    code () {
      return this.$route.query.code
    },
    /**
     * @return {Boolean} Discord連携しているかどうか
     */
    isDiscordLinkage () {
      return this.$store.getters['settingGroup/discordLinkage']
    }
  }
}
</script>
